import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Heading, { HeadingImageProps } from 'components/forum/Heading';
import Answers, { Props as AnswersProps } from 'components/forum/Answers';

interface Props {
  data: AnswersProps & { headingImage: HeadingImageProps };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { headingImage, answers } = props.data;

  return (
    <Layout departmentSlug="forum" title="Forum">
      <Heading question="Is There a Trump Doctrine?" image={headingImage} />
      <Core.ContentWrapper>
        <Answers answers={answers} />
        <Core.Caption>Portraits: Brett Affrunti</Core.Caption>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    answers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $directory } }
      sort: { fields: [frontmatter___position], order: ASC }
    ) {
      ...ForumAnswers
    }
    headingImage: file(
      relativePath: { regex: $directory }
      name: { eq: "heading-background" }
    ) {
      ...ForumHeadingImage
    }
  }
`;

export default DepartmentContent;
